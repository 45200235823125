import AddLoanAccountReschedule from "./loan-account-reschedule";
import Mixins from "../Mixins.js";

export default {
  mixins: [Mixins],
  name: "AddTLoanAccountReschedule",
  components: {
    AddLoanAccountReschedule,
  },
  destroyed() {
    this.$store.dispatch("CLEAR_OPTIONS_LOAN_ACCOUT");
  },
  methods: {
    centralBackToList() {
      this.$router.push("/loan/loan-account-reschedule");
    },
  },
};
